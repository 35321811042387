.container{
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #0b0c0f;
}

.App{
  background-color: #0b0c0f;
}