::-webkit-scrollbar-thumb {
  background-color: #2c2c2c;
  border-radius: 0.6vh;
}

::-webkit-scrollbar {
  background: transparent;
  height: 1vh;
  position: absolute;
  width: 0.6vh;
  background-color: #0b0c0f;
}

body {
  background-color: #f6fcff;
  padding: 0;
  margin: 0;
  font-family: "Inter", sans-serif;
}

* {
  border: none;
  box-shadow: none;
  box-sizing: border-box;
  font-style: normal;
  margin: 0;
  transition: all 0.2s ease-in-out;
  text-decoration: none;
  letter-spacing: 0.5px;
  resize: none;
  outline: none;
  border: 0;
  scroll-behavior: smooth;
}