.home{
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    flex-direction: column;
}
.home .divHome{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    min-height: 100vh;
    z-index: 1;
}
.home .circles{
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    overflow: hidden;   
    z-index: 0; 
}
.home .circles li{
    position: absolute;
    display: block;
    background-color: #ffffff09;
    width: 20px;
    height: 20px;
    list-style: none;
    border-radius: 10%;  
    bottom: -150px;
    animation: animate 25s linear infinite;
}
.home .circles li:nth-child(1) {
    left: 25%;
    width: 80px;
    height: 80px;
    animation-delay: 0s;
}
.home .circles li:nth-child(2) {
    left: 10%;
    width: 30px;
    height: 30px;
    animation-delay: 2s;
    animation-duration: 12s;
}
.home .circles li:nth-child(3) {
    left: 70%;
    width: 30px;
    height: 30px;
    animation-delay: 4s;
    animation-duration: 12s;
}
.home .circles li:nth-child(4) {
    left: 40%;
    width: 60px;
    height: 60px;
    animation-delay: 0s;
    animation-duration: 18s;
}
.home .circles li:nth-child(5) {
    left: 65%;
    width: 30px;
    height: 30px;
    animation-delay: 0s;
}
.home .circles li:nth-child(6) {
    left: 75%;
    width: 110px;
    height: 110px;
    animation-delay: 3s;
}
.home .circles li:nth-child(7) {
    left: 35%;
    width: 150px;
    height: 150px;
    animation-delay: 7s;
}
.home .circles li:nth-child(8) {
    left: 50%;
    width: 35px;
    height: 35px;
    animation-delay: 15s;
    animation-duration: 45s;
}
.home .circles li:nth-child(9) {
    left: 20%;
    width: 25px;
    height: 25px;
    animation-delay: 2s;
    animation-duration: 35s;
}
.home .circles li:nth-child(10) {
    left: 85%;
    width: 150px;
    height: 150px;
    animation-delay: 0s;
    animation-duration: 11s;
}

@keyframes animate {
    0%{
        transform: translateY(0) rotate(0deg);
        opacity: 1;
        border-radius: 0;
    }
    100%{
        transform: translateY(-1000px) rotate(720deg);
        opacity: 0;
        border-radius: 50%;
    }
}
.home .divHome img{
    height: auto;
    width: 18%;
    padding: 1.5%;
    border-radius: 0.3rem;
    margin-top: 2%;
    margin-bottom: 4%;
    animation: spin 5s linear infinite;
}
@keyframes spin {
    from {
        transform: rotate(0deg); 
    }
    to {
        transform: rotate(360deg); 
    }
}
.home .divHome h1{
    font-size: 3.5em;
    color: white;
    letter-spacing: 15px;
    font-weight: 600;
    text-align: center;
    margin-bottom: 1%;
}
.home .divHome h2{
    font-size: 2.2em;
    font-size: 600;
    animation: changeColor 5s linear infinite;
    margin-bottom: 2%;
    text-align: center;
}
@keyframes changeColor {
    0% {
      color: #ffba0a;
    }
    25% {
      color: #cf9e21;
    }
    50% {
      color: #bb922a;
    }
    75% {
      color: #ac7c03;
    }
    100% {
      color: #ffba0a;
    }
}
.home .divHome p{
    color: #888888;
    width: 30em;
    text-align: center;
}
.home .divHome .divHomeButtons{
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1%;
    width: 30em;
}
.home .divHome .divHomeButtons button{
    background-color: #0b0c0f;
    border: 0.8px solid #888888;
    padding: 5%;
    border-radius: 2rem;
    color: #e3e3e3;
    width: 8em;
}
.home .divHome .divHomeButtons button:hover{
    transform: scale(1.05);
}

.home .divNotices{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    min-height: 70vh;
    margin-bottom: 4%;
}
.home .divNotices h1{
    width: 100%;
    margin-bottom: 2%;
    margin-top: 2%;
    font-size: 1.8em;
    color: #757575;
}
.home .noticeItem{
    display: flex;
    align-items: start;
    justify-content: center;
    flex-direction: column;
    padding: 2%;
    border: 0.8px solid #2c2c2ccc;
    border-radius: 0.3rem;
    width: 90vw;
    margin-top: 0.5%;
    margin-bottom: 1%;
}
.home .noticeItem h1{
    color: #e3e3e3;
    font-size: 1.5em;
    font-weight: 500;
    margin-bottom: 1%;
    margin-top: 0;
}
.home .noticeItem h2{
    color:#888888 ;
    font-size: 1em;
    font-weight: 600;
}
.home .noticeItem p{
    color: #616161;
    font-size: 1em;
}
.home .noticeItem .listTags{
    list-style: none;
    display: flex;
    align-items: center;
    justify-content: start;
    margin: 0;
    padding: 0;
    width: 100%;
}
.home  .noticeItem .listTags li{
    padding: 0.3%;
    background-color: #ffba0a13;
    border-radius: 0.3rem;
    color: #ffba0a;
    width: 20vh;
    text-align: center;
    font-size: 0.7em;
    margin-right:  1%;
}

.home .divProducts{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    min-height: 70vh;
    padding: 0%;
    margin: 0%;
    flex-wrap: wrap;
    margin-bottom: 5%;
}
.home .divProducts h1{
    width: 100%;
    margin-bottom: 2%;
    font-size: 1.8em;
    margin-top: 5%;
    color: #757575;
}
.home .divProducts .containerProducts{
    display: flex;
    align-items: start;
    justify-content: start;
    flex-wrap: wrap;
    width: 90vw;
    gap: 15px;
    margin-bottom: 3%;
}
.home .divProducts .containerProducts .productItem{
    display: flex;
    align-items: start;
    justify-content: center;
    flex-direction: column;
    border: 0.8px solid #2c2c2ccc;
    padding: 2%;
    border-radius: 0.3rem;
    width: 18em;
}
.home .divProducts .containerProducts .productItem:hover{
    border: 0.8px solid #ffba0a;
    transform: scale(1.02);
}
.home .divProducts .containerProducts .productItem h1{
    color: #e3e3e3;
    padding: 0;
    font-size: 1.5em;
}
.home .divProducts .containerProducts .productItem h2{
    color:#4eb923 ;
    font-size: 1.1em;
    margin-bottom: 8%;
}
.home .divProducts .containerProducts .productItem ul{
    padding: 0;
    width: 100%;
    list-style: none;
    margin-bottom: 8%;
}
.home .divProducts .containerProducts .productItem li{
    color: #616161;
    margin-bottom: 3%;
    font-size: 0.9em;
}
.home .divProducts .containerProducts .productItem button{
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    width: 100%;
    padding: 3%;
    font-size: 0.8em;
    border-radius: 0.3rem;
    background-color: transparent;
    border: 0.8px solid #2c2c2ccc;
    color: #e3e3e3;
}
@media screen and (max-width: 1000px) {
    .home .divHome{
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        min-height: 100vh;
        z-index: 1;
    }
    .home .divHome h1{
        font-size: 2.5em;
        color: white;
        font-weight: 600;
        text-align: center;
        margin-bottom: 1%;
    }
    .home .divHome h2{
        font-size: 1.5em;
        text-align: center;
        font-size: 600;
        width: 15em;
        animation: changeColor 5s linear infinite;
        margin-bottom: 2%;
        text-align: center;
    }
    @keyframes changeColor {
        0% {
          color: #ffba0a;
        }
        25% {
          color: #cf9e21;
        }
        50% {
          color: #bb922a;
        }
        75% {
          color: #ac7c03;
        }
        100% {
          color: #ffba0a;
        }
    }
    .home .divHome p{
        color: #888888;
        width: 20em;
        text-align: center;
    }
    .home .noticeItem{
        display: flex;
        align-items: start;
        justify-content: center;
        flex-direction: column;
        padding: 5%;
        border: 0.8px solid #2c2c2ccc;
        border-radius: 0.3rem;
        width: 90vw;
        margin-top: 0.5%;
        margin-bottom: 1%;
    }
    .home .noticeItem h1{
        color: #e3e3e3;
        font-size: 1em;
        font-weight: 500;
        margin-bottom: 1%;
        margin-top: 0;
    }
    .home .noticeItem h2{
        color:#888888 ;
        font-size: 0.8em;
        font-weight: 600;
    }
    .home .noticeItem p{
        color: #616161;
        font-size: 0.8em;
    }
    .home .noticeItem .listTags{
        list-style: none;
        display: flex;
        align-items: center;
        justify-content: start;
        margin: 0;
        padding: 0;
        width: 100%;
    }
    .home  .noticeItem .listTags li{
        padding: 1%;
        background-color: #ffba0a13;
        border-radius: 0.3rem;
        color: #ffba0a;
        width: 20vh;
        text-align: center;
        font-size: 0.6em;
        margin-right:  1%;
    }
    .home .divProducts .containerProducts .productItem{
        display: flex;
        align-items: start;
        justify-content: center;
        flex-direction: column;
        border: 0.8px solid #2c2c2ccc;
        padding: 3%;
        border-radius: 0.3rem;
        width: 20.5em;
    }
    .home .divHome .divHomeButtons{
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 1%;
        width: 1em;
    }
    .home .divHome .divHomeButtons button{
        background-color: #0b0c0f;
        border: 0.8px solid #888888;
        padding: 3%;
        border-radius: 2rem;
        color: #e3e3e3;
        width: 8em;
    }
}

@media screen and (max-width: 700px) {
    .home .divHome{
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        min-height: 100vh;
        z-index: 1;
    }
    .home .divHome h1{
        font-size: 2em;
        color: white;
        font-weight: 600;
        text-align: center;
        margin-bottom: 1%;
    }
    .home .divHome h2{
        font-size: 1.2em;
        text-align: center;
        font-size: 600;
        width: 13em;
        animation: changeColor 5s linear infinite;
        margin-bottom: 2%;
        text-align: center;
    }
    @keyframes changeColor {
        0% {
          color: #ffba0a;
        }
        25% {
          color: #cf9e21;
        }
        50% {
          color: #bb922a;
        }
        75% {
          color: #ac7c03;
        }
        100% {
          color: #ffba0a;
        }
    }
    .home .divHome p{
        color: #888888;
        width: 15em;
        text-align: center;
    }
}
