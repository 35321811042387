.product{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
.product .headerContainerProducts{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    border: 0.8px solid #2c2c2ccc;
    border-radius: 0.3rem;
    padding: 5%;
    width: 110vh;
    margin-bottom: 1%;
}
.product .headerContainerProducts img{
    height: auto;
    width: 25%;
}
.product .headerContainerProducts .headerDivTextContainerProducts{
    display: flex;
    align-items: start;
    justify-content: center;
    flex-direction: column;
}
.product .headerContainerProducts .headerDivTextContainerProducts h1{
    color: #e3e3e3;
}
.product .headerContainerProducts .headerDivTextContainerProducts h2{
    color:#4eb923 ;
    font-size: 1.5em;
}
.product .headerContainerProducts .headerDivTextContainerProducts p{
    color: #888888;
    width: 90%;
}
.product .headerContainerProducts .headerDivTextContainerProducts button{
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: transparent;
    width: 100%;
    padding: 1%;
    border: 0.8px solid #2c2c2ccc;
    border-radius: 0.3rem;
    color: #bdbdbd;
}

.product .infoContainerProducts{
    display: flex;
    align-items: start;
    justify-content: start;
    border: 0.8px solid #2c2c2ccc;
    padding: 3%;
    width: 110vh;
    border-radius: 0.3rem;
}
.product .infoContainerProducts .listTags{
    list-style: none;
    display: flex;
    align-items: start;
    justify-content: start;
    flex-direction: column;
    width: 100%;
}
.product .infoContainerProducts .listTags li{
    color: #e3e3e3;
    width: 100%;
}

@media screen and (max-width: 700px) {
    .product .headerContainerProducts{
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        border: 0.8px solid #2c2c2ccc;
        border-radius: 0.3rem;
        padding: 5%;
        width: 15em;
        margin-bottom: 1%;
    }
    .product .headerContainerProducts img{
        height: auto;
        width: 25%;
        display: none;
    }
    .product .headerContainerProducts .headerDivTextContainerProducts{
        display: flex;
        align-items: start;
        justify-content: center;
        flex-direction: column;
    }
    .product .headerContainerProducts .headerDivTextContainerProducts h1{
        color: #e3e3e3;
        font-size: 1.5em;
    }
    .product .headerContainerProducts .headerDivTextContainerProducts h2{
        color:#4eb923 ;
        font-size: 1em;
    }
    .product .headerContainerProducts .headerDivTextContainerProducts p{
        color: #888888;
        width: 99%;
        font-size: 0.8em;
    }
    .product .headerContainerProducts .headerDivTextContainerProducts button{
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: transparent;
        width: 100%;
        padding: 1%;
        border: 0.8px solid #2c2c2ccc;
        border-radius: 0.3rem;
        color: #bdbdbd;
    }
    
    .product .infoContainerProducts{
        display: flex;
        align-items: start;
        justify-content: start;
        border: 0.8px solid #2c2c2ccc;
        padding: 3%;
        width: 15em;
        border-radius: 0.3rem;
    }
    .product .infoContainerProducts .listTags{
        list-style: none;
        display: flex;
        align-items: start;
        justify-content: start;
        flex-direction: column;
        width: 100%;
        padding: 0;
    }
    .product .infoContainerProducts .listTags li{
        color: #e3e3e3;
        width: 100%;
        font-size: 0.8em;
    }
}