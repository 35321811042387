.nf{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    z-index: 999;
}
.nf h1{
    width: 100%;
    margin-bottom: 2%;
    color: #888888;
    margin-top: 2%;
    font-size: 1.5em;
    text-align: center;
}
.nf h2{
    color: #e3e3e3;
    margin-bottom: 5%;
    font-size: 1.5em;
    text-align: center;
}
.nf button{
    background-color: transparent;
    padding: 3%;
    border-radius: 0.3rem;
    border: 0.8px solid #2c2c2ccc;
    color: #e3e3e3;
    width: 15em;
}