.faq{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
.faq img{
    height: auto;
    width: 8%;
    animation: spin 5s linear infinite;
    margin-top: 5%;
    margin-bottom: 5%;
}
@keyframes spin {
    from {
        transform: rotate(0deg); 
    }
    to {
        transform: rotate(360deg); 
    }
}
.faq h1{
    color: #e3e3e3;
    font-size: 1.5em;
    margin-bottom: 5%;
}

details{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    min-height: auto;
    padding: 1%;
    width: 40em;
    background-color: transparent;
    overflow: hidden;
    margin: 1%;
    border-radius: 0.3rem;
    border: 1px solid rgba(51, 51, 51, 0.384);
}
details summary{
    cursor: pointer;
    padding: 1.4vh 1.6vw;
    background-color: transparent;
    font-size: 1em;
    font-weight: 400;
    color: #e3e3e3;
}
details p {
    font-size: 0.8em;
    font-weight: 500;
    padding: 1.2vh 1.6vw;
    color: #888888;
}

@media screen and (max-width: 700px) {
    details{
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        min-height: auto;
        padding: 3%;
        width: 18em;
        background-color: transparent;
        overflow: hidden;
        margin: 0.8%;
        border: 1px solid rgba(51, 51, 51, 0.384);
    }
    details summary{
        cursor: pointer;
        padding: 1.4vh 1.6vw;
        background-color: transparent;
        font-size: 1em;
        font-weight: 600;
        color: #e3e3e3;
    }
    details p {
        font-size: 0.8em;
        font-weight: 500;
        padding: 1.2vh 1.6vw;
        color: #888888;
    }
}