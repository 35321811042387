.contact{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
.contact img{
    height: auto;
    width: 10%;
    animation: spin 5s linear infinite;
    margin-top: 5%;
}
@keyframes spin {
    from {
        transform: rotate(0deg); 
    }
    to {
        transform: rotate(360deg); 
    }
}
.contact h1{
    color: #e3e3e3;
    font-size: 1.5em;
    margin-bottom: 5%;
    margin-top: 3%;
}
.contact form{
    padding: 5%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    border: 0.8px solid #2c2c2ccc;
    border-radius: 0.3rem;
    margin-bottom: 3%;
}
.contact form input{
    width: 100%;
    padding: 4%;
    background-color: transparent;
    border: 0.8px solid #2c2c2ccc;
    margin: 2%;
    width: 18em;
    color: #888888;
    border-radius: 0.3rem;
}
.contact form input:focus{
    border: 0.8px solid #ffba0a;
}
.contact form textarea{
    width: 100%;
    padding: 4%;
    background-color: transparent;
    border: 0.8px solid #2c2c2ccc;
    margin: 2%;
    color: #888888;
    border-radius: 0.3rem;
}
.contact form textarea:focus{
    border: 0.8px solid #ffba0a;
}
.contact form button{
    width: 100%;
    padding: 4%;
    background-color: transparent;
    border: 0.8px solid #2c2c2ccc;
    margin: 2%;
    color: #888888;
    border-radius: 0.3rem;
}

@media screen and (max-width: 700px) {
    .contact form{
        padding: 3%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        border: 0.8px solid #2c2c2ccc;
        border-radius: 0.3rem;
        margin-bottom: 3%;
        width: 15em;
    }
    .contact form input{
        padding: 4%;
        background-color: transparent;
        border: 0.8px solid #2c2c2ccc;
        margin: 2%;
        width: 100%;
        color: #888888;
        border-radius: 0.3rem;
    }
    .contact form button{
        width: 100%;
        padding: 4%;
        background-color: transparent;
        border: 0.8px solid #2c2c2ccc;
        margin: 2%;
        color: #888888;
        border-radius: 0.3rem;
    }
}