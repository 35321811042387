.login{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
.login h1{
    color: #e3e3e3;
    font-size: 1.8em;
}
.login p{
    color: #888888;
    width: auto;
    text-align: center;
}
.login form{
    padding: 5%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    border: 0.8px solid #2c2c2ccc;
    border-radius: 0.3rem;
    margin-bottom: 3%;
}
.login form input{
    width: 100%;
    padding: 4%;
    background-color: transparent;
    border: 0.8px solid #2c2c2ccc;
    margin: 2%;
    width: 18em;
    color: #888888;
    border-radius: 0.3rem;
}
.login form input:focus{
    border: 0.8px solid #ffba0a;
}
.login form button{
    width: 100%;
    padding: 4%;
    background-color: transparent;
    border: 0.8px solid #2c2c2ccc;
    margin: 2%;
    color: #888888;
    border-radius: 0.3rem;
}

@media screen and (max-width: 700px) {
    .login form{
        padding: 3%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        border: 0.8px solid #2c2c2ccc;
        border-radius: 0.3rem;
        margin-bottom: 3%;
        width: 15em;
    }
    .login form input{
        padding: 4%;
        background-color: transparent;
        border: 0.8px solid #2c2c2ccc;
        margin: 2%;
        width: 100%;
        color: #888888;
        border-radius: 0.3rem;
    }
    .login form button{
        width: 100%;
        padding: 4%;
        background-color: transparent;
        border: 0.8px solid #2c2c2ccc;
        margin: 2%;
        color: #888888;
        border-radius: 0.3rem;
    }
}