nav{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.6%;
    width: 100%;
    background-color: #0b0c0f;
    border-bottom: 0.8px solid #2c2c2ccc;
    position: fixed;
    z-index: 999;
}
nav .btnLogout{
    border: 0.8px solid #2c2c2ccc;
    color: #bdbdbd;
    background-color: #d3313111;
    border: 0.8px solid #8a1717cc;
    border-radius: 0.3rem;
    padding: 3.4%;
    margin-right: 2%;
    width: 5em;
}
nav img{
    height: auto;
    width: 2.2em;
    margin-left: 2%;
    padding: 0.5%;
    background-color: transparent;
    border: 0.8px solid #2c2c2ccc;
    border-radius: 0.3rem;
}
nav .divContainerItens{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    margin-right: 2%;
}

nav .btnGroup{
    margin-right: 2%;
}

nav .btnMenu{
    border: 0.8px solid #2c2c2ccc;
    color: #bdbdbd;
}
nav .btnMenu:hover{
    border: 0.8px solid #2c2c2ccc;
    color: #bdbdbd;
}

nav .divContainerMenu{
    background-color: #0b0c0f;
    color: #e3e3e3;
    border: 0.8px solid #2c2c2ccc;
    padding: 3%;
}
nav .divContainerMenu .listItensContainerMenu{
    border-radius: 0.5rem;
    padding: 1.5%;
}
nav .divContainerMenu .itemsContainerMenu{
    padding: 5%;
    color: #bdbdbd;
    border-radius: 0.3rem;
}
nav .divContainerMenu .itemsContainerMenu:hover{
    background-color: #24242449;
}

.iconDisc{
    color: #bdbdbd;
    font-size: 2.4em;
    width: 1.5em;
    border: 0.8px solid #2c2c2ccc;
    border-radius: 0.3rem;
    padding: 2.8%;
    margin-right: 2%;
}
.iconInsta{
    color: #bdbdbd;
    font-size: 2.4em;
    width: 1.5em;
    border: 0.8px solid #2c2c2ccc;
    border-radius: 0.3rem;
    padding: 2.8%;
}